<template>
  <div class="row">
    <div class="col-xl-12 col-md-12"  v-show="(!loading && proyection.length !== 0)">
      <va-card class="goals-widget">
        <va-inner-loading :loading="loading">
          <div class="row justify-content-center">
            <div class="flex xs12">
              <h6 class="chart-title text-center">{{ $t('chart.proyection.title.Distribution') }}</h6>
            </div>
            <div
              class="flex xs12"
            >
              <div class="row">
                <div class="flex xs12">
                  <circle-chart-mult
                    :size="100"
                    :goal-data="proyection"
                  />
                </div>
              </div>
            </div>
          </div>
        </va-inner-loading>
      </va-card>
    </div>
    <div class="col-xl-12 col-md-12" v-show="(!loading && evang.length !== 0)">
      <va-card class="goals-widget">
        <va-inner-loading :loading="loading">
          <div class="row justify-content-center">
            <div class="flex xs12">
              <h6 class="chart-title text-center">{{ $t('chart.evang.title') }}</h6>
            </div>
            <div class="flex xs12">
              <div class="row">
                <div class="flex xs12">
                  <circle-chart-mult
                    :size="100"
                    :goal-data="evang"
                  />
                </div>
              </div>
            </div>
          </div>
        </va-inner-loading>
      </va-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const CircleChartMult = () => import(/* webpackPrefetch: true */ '../statistics/charts/CircleChartMult')

export default {
  name: 'dashboard-goals-charts',
  components: { CircleChartMult },
  data () {
    return {
      periodYear: 0,
      loading: false,
      error: false,
      rawGoals: [],
      proyection: [],
      evang: [],
    }
  },
  computed: {
    ...mapGetters(['currentLocale']),
  },
  created () {
    this.getGoalsData()
  },
  methods: {
    async getGoalsData () {
      this.getEquipament()
      this.getEvang()
    },
    async getEquipament () {

      this.loading = true
      this.error = false
      this.proyection = []
      let d = null
      try {
        d = await this.$http.get('/teams/equipments/equipmentnumbers')
      } catch (e) {
        this.loading = true
        return
      }

      this.rawGoals = d.data.data
      this.proyection = [
        { label: this.$t('chart.proyection.label.NoEquipment'), count: this.rawGoals[0]['teams.equipements.none'] },
        { label: this.$t('chart.proyection.label.JFBackpack'), count: this.rawGoals[0]['teams.equipements.backpack'] },
        { label: this.$t('chart.proyection.label.MiniSolarSet'), count: this.rawGoals[0]['teams.equipements.solar'] },
        { label: this.$t('chart.proyection.label.Handheld'), count: this.rawGoals[0]['teams.equipements.handheld'] },
        { label: this.$t('chart.proyection.label.LargeSet'), count: this.rawGoals[0]['teams.equipements.large'] },
      ]
    },
    async getEvang () {
      let d = null
      this.evang = []
      try {
        d = await this.$http.get('/presentations/evangelisticMethodsNumbers')
      } catch (e) {
        this.loading = true
        return
      }
      this.rawGoals = d.data.data
      for (let i = 0; i < this.rawGoals.length; i++) {
        this.evang.push({
          label: this.$t(this.rawGoals[i].name ? this.rawGoals[i].name : 'n/a'),
          count: this.rawGoals[i]['count(*)'],
        })
      }

      this.loading = false
      if (this.evang.length === 0) {
        this.$emit('empty')
      } else {
        this.$emit('full')
      }
    },
  },
}
</script>

<style lang="scss">
.align-self-center {
  align-self: center;
}

.goals-widget {
  span {
    float: right;
  }

  .chart-title {
    padding-top: 20px;
  }
}
</style>
